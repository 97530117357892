import throttle from 'lodash/throttle';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      show: false,
      localStorageTimeOutGeneral: null,
      localStorageMouseLeaveGeneral: null,
      localStorageTimeOutBlog: null,
      localStorageMouseLeaveBlog: null,
      timeout: 0,
      events: ['mousemove', 'pointermove', 'touchmove'],
      listener: null,
    };
  },
  computed: {
    ...mapState({
      videoPlay: state => state.modal.videoPlay,
    }),
    generalConditionMet() {
      return (
        this.localStorageMouseLeaveGeneral !== 'true' &&
        this.localStorageTimeOutGeneral !== 'true' &&
        this.videoPlay.length === 0 &&
        !this.anyModalIsOpen()
      );
    },
    blogConditionMet() {
      return (
        this.localStorageTimeOutBlog !== 'true' &&
        this.localStorageMouseLeaveBlog !== 'true' &&
        this.videoPlay.length === 0 &&
        !this.anyModalIsOpen()
      );
    },
    includeBlog() {
      const regex = /\/blog/;
      const url = window.location.href;
      return regex.test(url);
    },
    modalId() {
      return this.includeBlog
        ? this.$store.state.modal.blogModalData.form_id
        : this.$store.state.modal.modalData.form_id;
    },
  },
  methods: {
    triggerModal(storageKey, modalType, closeColor) {
      const skipExitModalUrls = [
        '/security/report-a-vulnerability',
        '/terms/legal',
      ];

      if (
        skipExitModalUrls.some(url => window.location.pathname.includes(url))
      ) {
        return;
      }

      // trigger modal & analytics
      this.$root.$emit('open-deputy-modal', {
        modalTarget: modalType,
        id: this.modalId,
        color: closeColor,
      });
      window.localStorage.setItem(storageKey, 'true');
      // remove mouseleave listener after modal triggered
      document.body.removeEventListener(
        'mouseleave',
        this.triggerGeneralPopUpMouseleave
      );
      // remove mouseleave listener after modal triggered blog
      document.body.removeEventListener(
        'mouseleave',
        this.triggerBlogPopUpMouseleave
      );
      // remove event listener after modal triggered
      this.events.forEach(event => {
        if (this.listener !== null) {
          document.removeEventListener(event, this.listener, true);
        }
      });
    },
    activeExitPopUpModal() {
      // trigger General modal @ mouse leave
      if (
        !this.includeBlog &&
        Object.keys(this.$store.state.modal.modalData).length !== 0
      ) {
        this.getLocalStorage();
        if (this.localStorageMouseLeaveGeneral !== 'true') {
          document.body.addEventListener(
            'mouseleave',
            this.triggerGeneralPopUpMouseleave
          );
        }
        // trigger General modal after 30 sec if no mouse move
        if (
          this.localStorageTimeOutGeneral !== 'true' &&
          this.localStorageMouseLeaveGeneral !== 'true'
        ) {
          // add event listener on mouse move, laptop touch pad & mobile
          (this.listener = throttle(this.triggerGeneralPopUpTimeOut, 500)),
            this.events.forEach(event => {
              document.addEventListener(event, this.listener, true);
            });
        }
        // trigger Blog modal @ mouse leave
      } else if (
        this.includeBlog &&
        Object.keys(this.$store.state.modal.blogModalData).length !== 0
      ) {
        this.getLocalStorage();
        if (this.localStorageMouseLeaveBlog !== 'true') {
          document.body.addEventListener(
            'mouseleave',
            this.triggerBlogPopUpMouseleave
          );
        }
        // trigger Blog modal after 30 sec if no mouse move
        if (
          this.localStorageTimeOutBlog !== 'true' &&
          this.localStorageMouseLeaveBlog !== 'true'
        ) {
          // add event listener on mouse move, laptop touch pad & mobile
          (this.listener = throttle(this.triggerBlogPopUpTimeOut, 500)),
            this.events.forEach(event => {
              document.addEventListener(event, this.listener, true);
            });
        }
      }
    },
    triggerBlogPopUpMouseleave() {
      this.getLocalStorage();
      if (this.blogConditionMet) {
        this.triggerModal(
          'exitPopupBlogMouseLeave',
          '#blogExitPopup',
          'darkPurpleMedium'
        );
      }
    },
    triggerGeneralPopUpMouseleave() {
      this.getLocalStorage();
      if (this.generalConditionMet) {
        this.triggerModal(
          'generalExitPopupMouseLeave',
          '#generalExitPopup',
          'peach'
        );
      }
    },
    triggerGeneralPopUpTimeOut() {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.getLocalStorage();
        if (this.generalConditionMet) {
          this.triggerModal(
            'generalExitPopupTimeOut',
            '#generalExitPopup',
            'peach'
          );
        }
      }, 30000);
    },
    triggerBlogPopUpTimeOut() {
      clearTimeout(this.timeout);
      this.timeout = window.setTimeout(() => {
        this.getLocalStorage();
        if (this.blogConditionMet) {
          this.triggerModal(
            'exitPopupBlogTimeOut',
            '#blogExitPopup',
            'darkPurpleMedium'
          );
        }
      }, 20000);
    },
    getLocalStorage() {
      this.localStorageTimeOutGeneral = window.localStorage.getItem(
        'generalExitPopupTimeOut'
      );
      this.localStorageTimeOutBlog = window.localStorage.getItem(
        'exitPopupBlogTimeOut'
      );
      this.localStorageMouseLeaveGeneral = window.localStorage.getItem(
        'generalExitPopupMouseLeave'
      );
      this.localStorageMouseLeaveBlog = window.localStorage.getItem(
        'exitPopupBlogMouseLeave'
      );
    },
  },
};
